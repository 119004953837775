import axios from 'axios'
import { AddToCartDM, UpdateCartDM, RemoveFromCartDM } from '@/model/data/cart-data-model'
import { CartUM, CartProductUM, CartPaymentMethodUseUM, CartPaymentMethodsUM, CartRewardPointUM, CartProductGiftUM, CartActivitiesUseUM, CartActivitiesUM, CartAmountResultUM } from '@/model/ui/cart-ui-model'
import errorCode from '../assets/cartError.json'
import store from '../store'

const cart = {
  // *整理 model
  CleanCart: function (rsp) {
    const data = rsp
    const d = new CartUM()

    if (data.items !== null) {
      d.Products = data.items.map((pdi) => {
        const p = new CartProductUM()
        p.Id = pdi.productId
        p.OptionId = pdi.optionId
        p.Name = pdi.productName
        p.OptionName = pdi.optionName
        p.ImageUrl = pdi.imageUrl
        p.Price = pdi.price
        p.Quantity = pdi.quantity
        p.Stock = pdi.stock
        p.Type = pdi.type

        // * 商品贈品
        if (pdi.gift !== null) {
          p.Gift = pdi.gift.map((gift) => {
            const item = new CartProductGiftUM()
            item.Id = gift.giftId
            item.Name = gift.giftName
            return item
          })
        }
        // if (pdi.gift !== null) {
        //   p.Gift = new CartProductGiftUM()
        //   p.Gift.Id = pdi.gift.giftId
        //   p.Gift.Name = pdi.gift.giftName
        // }
        return p
      })
    }

    // * 下單滿額贈品
    if (data.orderGifts) {
      d.OrderGifts = data.orderGifts.map((gift) => {
        const g = new CartProductGiftUM()
        g.Id = gift.giftId
        g.Name = gift.giftName
        g.LimitPrice = gift.limitPrice

        return g
      })
    }

    // * 回饋點數
    if (data.rewardPoint !== null) {
      d.RewardPoint = new CartRewardPointUM()
      d.RewardPoint.Total = data.rewardPoint.total
      d.RewardPoint.CanUse = data.rewardPoint.canUse
      d.RewardPoint.Used = data.rewardPoint.used
    }

    // * 滿額折
    if (data.activities !== null) {
      d.Activities = new CartActivitiesUseUM()
      d.Activities.Used = data.activities.used
      d.Activities.CanUse = data.activities.canUse.map((act) => {
        const f = new CartActivitiesUM()
        f.Id = act.activityId
        f.Name = act.activityName
        f.Difference = act.difference
        f.DiscountAmount = act.discountAmount
        f.LimitPrice = act.limitPrice
        f.DiscountType = act.discountType
        f.IsConform = act.isConform
        return f
      })
    }

    // * 付款方式
    if (data.paymentMethods !== null) {
      d.PaymentMethods = new CartPaymentMethodUseUM()
      d.PaymentMethods.Used = data.paymentMethods.used
      d.PaymentMethods.CanUse = data.paymentMethods.canUse.map((pay) => {
        const m = new CartPaymentMethodsUM()
        m.Code = pay.paymentCode
        m.Name = pay.paymentName
        m.LimitPrice = pay.limitPrice
        m.DisplayOrder = pay.displayOrder

        return m
      })
    }
    // * 付款方式及紅利折抵排序
    d.PaymentMethods.CanUse.sort((a, b) => {
      return a.DisplayOrder - b.DisplayOrder
    })

    // * 金額
    d.AmountResult = new CartAmountResultUM()
    d.AmountResult.PayableAmount = data.amountResult.payableAmount
    d.AmountResult.PointDiscount = data.amountResult.pointDiscount
    d.AmountResult.ActivityDiscount = data.amountResult.activityDiscount
    d.AmountResult.TotalDiscount = data.amountResult.totalDiscount
    d.AmountResult.PaidAmount = data.amountResult.paidAmount

    return d
  },
  // * 取得購物車商品
  GetCart: async function () {
    try {
      const url = `${process.env.VUE_APP_API}/api/newCart/mycart`
      const rsp = await axios.get(url)
      if (rsp === null || rsp.data === null) {
        return null
      }

      if (rsp.data.rtnCode === 5002) {
        localStorage.setItem('cartStatus2', '您已達該活動品限購數量上限')
      }

      // 購物車數量為 0
      // if (rsp.data.rtnCode !== 0) {
      //   store.commit('getcartNum', 0)
      //   return {
      //     isSuccess: false,
      //     msg: rsp.data.rtnMsg
      //   }
      // }
      if (!rsp.data.info || !rsp.data.info.items) {
        store.commit('getcartNum', 0)
        const msg = await cart.checkCode(rsp)
        return {
          isSuccess: false,
          msg: `${msg}(${rsp.data.rtnCode})`
        }
      }

      // * 計價資訊存進 Vuex
      store.commit('getCartCalculate', {
        items: rsp.data.info.items,
        useRewardPoint: rsp.data.info.rewardPoint.used || 0,
        useCoupon: null, // papa 暫無折價券
        useActivities: rsp.data.info.activities.used[0] || [],
        useRewardMoney: 0, // papa 暫無福利金
        usePaymentMethod: ''
      })
      // * 5001
      if (rsp.data.rtnCode !== 0) {
        const d = await cart.CartCalculate()
        return d
      }
      const d = await cart.CartCalculate()

      return d
    } catch (err) {
      if (err.response.status === 401) {
        if (document.cookie.replace(/(?:(?:^|.*;\s*)PaPaLoginToken\s*=\s*([^;]*).*$)|^.*$/, '$1')) {
          return cart.GetCart()
        }
      }
    }
  },
  // * 取得購物車商品總數
  GetCartNum: function (d) {
    let cartNum = 0
    if (d.Products.length > 0) {
      d.Products.forEach((pd) => {
        cartNum += pd.Quantity
      })
    }

    store.commit('getcartNum', cartNum)
  },
  // * 購物車計價
  CartCalculate: async function () {
    try {
      const url = `${process.env.VUE_APP_API}/api/newCart/cartCalculate`
      const res = await axios.post(url, store.state.cartCalculate)

      if (res == null || res.data == null) {
        return null
      }

      if (res.data.info == null) {
        store.commit('getcartNum', 0)
        const msg = await cart.checkCode(res)
        return {
          isSuccess: false,
          msg: `${msg}(${res.data.rtnCode})`
        }
      }

      if (res.data.rtnCode !== 0 && res.data.rtnCode === 5030) {
        const p = cart.CleanCart(res.data.info)
        cart.GetCartNum(p)
        cart.resetUsed(res.data.info)
        const errorMessage = res.data.rtnMsg
        const regex = /P\d+/g
        const matches = errorMessage.match(regex) || []
        return {
          isSuccess: false,
          errProducts: matches,
          data: p,
          rtnCode: res.data.rtnCode
        }
      }

      if (res.data.rtnCode !== 0) {
        const p = cart.CleanCart(res.data.info)
        cart.GetCartNum(p)
        cart.resetUsed(res.data.info)
        const msg = await cart.checkCode(res)
        return {
          isSuccess: false,
          msg: `${msg}(${res.data.rtnCode})`,
          data: p
        }
      }

      const p = cart.CleanCart(res.data.info)
      cart.GetCartNum(p)
      cart.resetUsed(res.data.info)

      return p
    } catch (err) {
      if (err.response.status === 401) {
        if (document.cookie.replace(/(?:(?:^|.*;\s*)PaPaLoginToken\s*=\s*([^;]*).*$)|^.*$/, '$1')) {
          cart.CartCalculate()
        }
      }
    }
  },
  // * 加入購物車
  AddToCart: async function (item, qty) {
    try {
      const url = `${process.env.VUE_APP_API}/api/newCart/add`
      const reqData = new AddToCartDM()
      reqData.productId = item.productId
      reqData.optionId = item.optionId
      reqData.productName = item.productName
      reqData.costPrice = item.costPrice
      reqData.sellPrice = item.sellPrice
      reqData.quantity = qty
      const res = await axios.post(url, reqData)
      if (res == null || res.data == null || res.data.rtnCode !== 0) {
        return null
      }

      if (res.data.info == null) {
        return null
      }
      // * 計算購物車商品總數
      let cartNum = 0
      res.data.info.items.forEach((pd) => {
        cartNum += pd.quantity
      })
      store.commit('getcartNum', cartNum)

      return {
        isSuccess: true
      }
    } catch (err) {
      if (err.response.status === 401) {
        if (document.cookie.replace(/(?:(?:^|.*;\s*)PaPaLoginToken\s*=\s*([^;]*).*$)|^.*$/, '$1')) {
          cart.AddToCart(item, qty)
        }
      }
    }
  },
  // * 加入購物車(多品)
  AddMultipleToCart: async function (prds) {
    try {
      const url = `${process.env.VUE_APP_API}/api/newCart/addMultiple`
      const list = prds.map((prd) => {
        const g = new AddToCartDM()
        g.productId = prd.Id
        g.optionId = prd.OptionId
        g.quantity = prd.Quantity
        g.productName = prd.Name
        g.costPrice = prd.MSRP
        g.sellPrice = prd.Price
        return g
      })

      const res = await axios.post(url, list)
      if (res == null || res.data == null) {
        return null
      }

      if (res.data.rtnCode !== 0) {
        const msg = await cart.checkCode(res)
        return {
          isSuccess: false,
          msg: `${msg}(${res.data.rtnCode})`
        }
      }

      if (res.data.info == null) {
        return null
      }
      // * 計算購物車商品總數
      let cartNum = 0
      res.data.info.items.forEach((pd) => {
        cartNum += pd.quantity
      })
      store.commit('getcartNum', cartNum)

      return {
        isSuccess: true
      }
    } catch (err) {
      if (err.response.status === 401) {
        if (document.cookie.replace(/(?:(?:^|.*;\s*)PaPaLoginToken\s*=\s*([^;]*).*$)|^.*$/, '$1')) {
          cart.AddMultipleToCart(prds)
        }
      }
    }
  },
  // * 購物車商品修改數量
  UpdateCart: async function (item) {
    try {
      const url = `${process.env.VUE_APP_API}/api/newCart/change`
      const data = new UpdateCartDM()
      data.productId = item.productId
      data.optionId = item.optionId
      data.quantity = item.quantity
      const rsp = await axios.post(url, data)

      if (rsp === null || rsp.data === null) {
        return null
      }
      // ?購物車數量歸 0
      if (!rsp.data.info) {
        store.commit('getcartNum', 0)
        return null
      }

      const info = store.state.cartCalculate
      info.items = rsp.data.info.items
      store.commit('getCartCalculate', info)
      const d = await cart.CartCalculate()

      return d
    } catch (err) {
      if (err.response.status === 401) {
        if (document.cookie.replace(/(?:(?:^|.*;\s*)PaPaLoginToken\s*=\s*([^;]*).*$)|^.*$/, '$1')) {
          return cart.UpdateCart(item)
        }
      }
    }
  },
  // * 商品移出購物車
  RemoveFromCart: async function (item) {
    try {
      const url = `${process.env.VUE_APP_API}/api/newCart/remove`
      const data = new RemoveFromCartDM()
      data.productId = item.productId
      data.optionId = item.optionId
      const rsp = await axios.post(url, data)

      if (rsp === null || rsp.data === null) {
        return null
      }

      // ?購物車數量歸 0
      if (!rsp.data.info || rsp.data.info.items.length === 0) {
        store.commit('getcartNum', 0)
        const msg = await cart.checkCode(rsp)
        return {
          isSuccess: false,
          msg: `${msg}(${rsp.data.rtnCode})`
        }
      }

      if (rsp.data.rtnCode !== 0) {
        const p = cart.CleanCart(rsp.data.info)
        cart.GetCartNum(p)
        cart.resetUsed(rsp.data.info)
        const msg = await cart.checkCode(rsp)
        return {
          isSuccess: false,
          msg: `${msg}(${rsp.data.rtnCode})`,
          data: p
        }
      }

      const info = store.state.cartCalculate
      info.items = rsp.data.info.items

      store.commit('getCartCalculate', info)
      const d = await cart.CartCalculate()

      return d
    } catch (err) {
      if (err.response.status === 401) {
        if (document.cookie.replace(/(?:(?:^|.*;\s*)PaPaLoginToken\s*=\s*([^;]*).*$)|^.*$/, '$1')) {
          return cart.RemoveFromCart(item)
        }
      }
    }
  },
  // * 所有商品移出購物車
  RemoveAllFromCart: async function () {
    try {
      const url = `${process.env.VUE_APP_API}/api/newCart/removeAll`
      const rsp = await axios.post(url)
      if (rsp === null || rsp.data === null) {
        return null
      }

      if (rsp.data.rtnCode !== 5003) {
        const msg = await cart.checkCode(rsp)
        return {
          isSuccess: false,
          msg: `${msg}(${rsp.data.rtnCode})`
        }
      }

      store.commit('getCartCalculate', {})

      return {
        isSuccess: true
      }
    } catch (err) {
      if (err.response.status === 401) {
        if (document.cookie.replace(/(?:(?:^|.*;\s*)PaPaLoginToken\s*=\s*([^;]*).*$)|^.*$/, '$1')) {
          return cart.RemoveAllFromCart()
        }
      }
    }
  },
  // *更新已使用狀態(Vuex)
  resetUsed (info) {
    const obj = {
      items: info.items,
      useRewardPoint: info.rewardPoint.used,
      useCoupon: null,
      useActivities: info.activities.used,
      useRewardMoney: 0,
      usePaymentMethod: info.paymentMethods.used
    }

    store.commit('getCartCalculate', obj)
  },
  // *比對 rtnCode
  checkCode (res) {
    let msg = ''
    for (let i = 0; i < errorCode.length; i++) {
      if (errorCode[i].code === res.data.rtnCode) {
        msg = errorCode[i].msg
      }
    }

    if (!msg) {
      msg = '系統錯誤，請洽客服'
    }

    return msg
  }
}

export default cart
